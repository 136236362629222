/* NavBar.module.css */
@import url(var.css);
@import url(module.font.css);

.navbar {
    font-family: 'Roboto', sans-serif;
    width: 100vw;
    color: white;
    transition: background-color 0.3s ease;
    text-align: center;
    /* Smooth transition for background color */
}

.navbar-collapse {
    background-color: white !important;
}

.navLink {
    font-weight: 1500;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: color 0.3s, background-color 0.3s;
    text-decoration: none;
    border-radius: 5px;
    margin-left: 30px;
    margin-right: 30px;
}

.navLink:hover {
    background-color: rgba(0, 0, 0, 0.444);
}

/* Navbar changes color when scrolling */
.scrollingNavbar {
    background-color: rgb(207, 182, 182, 0.651) !important;
    /* Dark background color when scrolled */
}

.navbar-expand-lg .navbar-collapse {
    background-color: white;
    /* Or any color you prefer */
}

/* @media (max-width: 991px) {
    .navbar-expand-lg .navbar-collapse {
        background-color: white;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-NavBar_navLink__Ev7TO nav-link {
        background: red;
    }

    .navbar-expand-lg .navbar-collapse {
        background-color: white;
    }
} */