.section {
    background: url(../img/v2/bg2.gif) no-repeat;
    background-size: cover;
    background-position: center 30%;
    min-height: 100vh;
    /* Use min-height to make sure the section stretches */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.con {
    background-color: rgba(0, 0, 0, 0.152);
    border-radius: 10px;
    width: 90%;
    max-width: 800px;
    overflow: hidden;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.heading {
    font-family: Grandiflora One;
    color: white;
    font-size: 330%;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.paragraph {
    color: white;
    font-size: 2vh;
    letter-spacing: 0.7cap;
    text-align: center;
    font-family: catchyMager;
    font-weight: bold;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 100%;
    word-break: break-word;
    display: block;
    margin: 0 auto;
}

/* Link Style */
.lk {
    color: rgb(247, 203, 243);
    /* The color you specified for links */
    font-weight: bold;
    /* Keeping the bold style */
    text-decoration: none;
    /* Ensuring links don't have underlines unless hovered */
}

.lk:hover {
    text-decoration: underline;
    /* Adding underline on hover for better user interaction */
}

.spacer {
    height: 15%;
}
.spacer2 {
    height: 10%;
}

/* Adjust for mobile responsiveness */
@media only screen and (max-width: 991px) {
    .section {
        padding-top: 15vh;
        padding-bottom: 10vh;
        background: url(../img/v2/bg2.gif) no-repeat;
        background-size: cover;
        background-position: center 30%;
        min-height: auto;
    }

    .con {
        background-color: rgba(0, 0, 0, 0.252);
        border-radius: 10px;
        padding: 20px;
    }

    .heading {
        font-size: 290%;
    }

    .paragraph {
        font-size: 70%;
        letter-spacing: 0.8cap;
        text-align: center;
    }

    .lk {
        font-size: 90%;
        /* Smaller font size for links on mobile */
    }
}