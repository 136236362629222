@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https: //fonts.google.com/specimen/Great+Vibes?query=Great+Vibes');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+BE+VLG:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grandiflora+One&family=Playwrite+BE+VLG:wght@100..400&display=swap');

@font-face {
    font-family: "AbsoluteBeautyScriptRegular";
    src: local("AbsoluteBeautyScriptRegular"),
        url("../fonts/AbsoluteBeautyScriptRegular.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "Flowerheart";
    src: local("FlowerheartpersonaluseRegular-AL9e2"),
        url("../fonts/FlowerheartpersonaluseRegular-AL9e2.otf") format("opentype");
    font-weight: normal;
}

@font-face {
    font-family: "catchyMager";
    src: local("catchyMager"),
        url("../fonts/CatchyMager-Normal.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "commuterssans";
    src: local("commuterssans"),
        url("../fonts/commuterssans.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "commuterssans-light";
    src: local("commuterssans-light"),
        url("../fonts/CommutersSans-Light.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "roboto";
    src: local("roboto"),
        url("../fonts/roboto.thin.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "rollingpen";
    src: local("rollingpen"),
        url("../fonts/RollingPenBasicTwo.otf") format("truetype");
    font-weight: normal;
}