@import url('var.css');
@import url('module.font.css');

/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    width: 100%;
    background-color: #FEE8D1 !important;
}

/* Section Styles */
section {
    min-height: 100vh;
    /* Ensures section is at least full height but expands if needed */
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    /* Prevents unnecessary overflow */
}

/* Home Section */
#home {
    background-image: url('../img/v2/L_L.ok-removebg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 60%;
    background-position: 40% 10%;
}

#home h1 {
    color: black;
    font-weight: 600;
    font-size: 1390%;
    text-align: center;
    font-family: AbsoluteBeautyScriptRegular;
    line-height: 80%;
}

#home h2 {
    color: black;
    font-weight: bold;
    font-style: oblique;
    font-size: 390%;
    text-align: center;
    font-family: Grandiflora One;
}

#home h4 {
    display: flex;
    flex-direction: row;
    font-size: 390%;
    font-weight: bold;
    text-align: center;
    font-family: Grandiflora One;
}

#home h4:before,
#home h4:after {
    content: "";
    flex: 1 1;
    border-bottom: 0.5px solid #000;
    margin: auto;
    padding-top: 2%;
}

/* Timer */
#timer {
    text-align: center;
    font-weight: lighter;
}

#timer .tm-cont {
    width: 10%;
    font-size: 1vw;
    color: black;
}

#timer .tm-cont p {
    padding-top: 20%;
    margin: 0;
    font-size: 3vw;
    font-weight: 200;
    color: black;
}

#timer .bb {
    border-right: thin solid black;
}

/* Proverb Section */
#proverbe {
    min-height: 100vh;
    width: 100%;
    color: black;
    text-align: center;
    font-family: Grandiflora One;
    font-weight: 200;
    background: url('../img/v2/plage.jpeg') no-repeat center center / cover;
}

#prv {
    /* background-color: red; */
    padding-top: 20vh;
    margin-top: -70vh;
}

/* Spacers */
.spacer1 {
    height: 10vh;
    /* 10% of the viewport height */
}

.spacer2 {
    height: 5vh;
    /* 5% of the viewport height */
}

.spacer3 {
    height: 25vh;
    /* 25% of the viewport height */
}

.spacer4 {
    height: 15vh;
    /* 15% of the viewport height */
}

/* About Section */
#about {
    min-height: 100vh;
    /* Allows it to expand if content is larger */
    height: auto;
    color: white;
    text-align: center;
    font-family: var(--font_1);
    font-weight: 200;
    background: url('../img/v2/bg2.gif') no-repeat center 30% / cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#about_filter {
    width: 50vw;
    background-color: rgba(0, 0, 0, 0.102);
    position: relative;
}

#prog p {
    color: white;
    font-size: 200%;
    letter-spacing: 1cap;
}

.prog_left p {
    color: white;
    font-size: 100%;
    letter-spacing: 1cap;
}

.bb {
    border-right: thin solid var(--font-color1);
}

/* Questions Section */
#question {
    min-height: 45vh;
    height: auto;
    background-color: var(--color_5);
    text-align: center;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#question .bb {
    border-right: thin solid black;
}

#qu_tittle p {
    font-family: var(--font_1);
    font-weight: 200;
    font-size: 200%;
    letter-spacing: 1cap;
}

#qr {
    text-align: center;
}

.qr_col {
    align-items: center;
}

.qr_tittle {
    font-weight: lighter;
    font-style: oblique;
}

.qr_content {
    font-weight: lighter;
    font-size: 80%;
}

/* Carousel */
#carousel {
    min-height: 100vh;
    background-color: var(--color_5);
}

.carousel-item {
    text-align: center;
}

/* Contact Section */
#contact {
    min-height: 30vh;
    padding: 50px 0;
    background-color: var(--color_5);
}

#contact_title p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

#contact_info {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.contact_col_left {
    text-align: right;
    font-family: AbsoluteBeautyScriptRegular;
    border-right: thin solid black;
}

.contact_col_left p {
    font-size: 400%;
}

.contact_col_right {
    text-align: left;
    font-family: Grandiflora One;
}

.contact_col_right p {
    font-size: 180%;
}

/* More Info */
#more_info {
    min-height: 20vh;
    text-align: center;
}