@media only screen and (max-width: 900px) {

    html,
    body {
        width: 100%;

        /* overflow-x: hidden; */
    }

    #home {
        background: url(../img/v2/L_L.ok-removebg.png) no-repeat fixed;
        background-size: 170%;
        background-position: 70% 50%;
    }

    #home h1 {
        /* padding-top: 90%; */
        color: black;
        font-size: 790%;
        text-align: center;
        font-family: AbsoluteBeautyScriptRegular;
        line-height: 80%;
    }

    #home h2 {
        color: black;
        font-weight: bold;
        font-style: oblique 30deg;
        font-size: 290%;
        text-align: center;
        font-family: Grandiflora One;
        line-height: 60%;
        text-shadow: 1px 1px 2px white;
    }

    #timer .tm-cont {
        width: 10%;
        font-size: 3vw;
        color: black;
    }

    #timer .tm-cont p {
        padding-top: 20%;
        margin: 0;
        font-size: 12vw;
        font-weight: 200;
        color: black;
    }

    /*#home #info h3 {
        font-weight: 80;
        border-bottom: thin solid var(--font-color2);
        font-size: 3vw;
    }

    #home #info h5 {
        font-weight: 100;
        font-size: 3vw;
    } */

    #proverbe {
        background: url(../img/v2/plage2.jpeg) no-repeat;
        background-size: cover;
        /* background-position: 80% center; */
    }

    .spacer1 {
        height: 5vh;
        /* 10% of the viewport height */
    }

    .spacer4 {
        height: 15%;
    }

    #about {
        height: 195vh;
        background-position: center top;
        
    }

    /* #about_info {
        font-size: 2vw;
        letter-spacing: 1cap;
        color: black;

    } */

    .prog_left p {
        color: white;
        font-size:80%;
        letter-spacing: 1cap;
        font-family: Grandiflora One;
        font-weight: bold;
    }

    #about_filter {
        background-color: rgba(0, 0, 0, 0.444);
        width: 100%;
        height: 195vh;
        display: flex;
            justify-content: center;
            /* Vertically centers content */
            align-items: center;
            /* Horizontally centers content */
            flex-direction: column;
    }

    #question {
        height: 50vh;
        background-color: var(--font-color1);
    }

    .qr_content {
        /* font-family: "Playwrite BE VLG", cursive; */
        font-weight: lighter;
        font-size: 60%;
        /* font-style: italic; */

    }


    #prog p {
        font-size: 140%;
    }

    #qu_tittle p {
        font-size: 150%;
    }

    .qr_tittle {
        font-size: 80%;
    }
}

@supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    body {

        /* min-height: ; */
    }

    #home {
        background: url(../img/v2/L_L.ok-removebg.png) no-repeat scroll;
        background-size: 170%;
        background-position: 70% 50%;
    }

    #about {
        height: 250vh;
        background-position: center top;
    }

    #about_filter {
        background-color: rgba(0, 0, 0, 0.444);
        width: 100%;
        height: 250vh;
    }

    #prog p {
        font-size: 150%;
    }

    .prog_left p {
        color: white;
        font-size: 80%;
        letter-spacing: 1cap;
    }

    #qu_tittle p {
        font-size: 150%;
    }

    .qr_tittle {
        font-size: 80%;
    }

    #question {
        /* height: 40vh; */
        background-color: var(--font-color1);
        /* overflow: auto; */
        display: block;
    }

    /* #about {
        height: 60vh;
        background-position: center 0%;
    }
    #question {
        height: 50vh;
        background-color: var(--font-color1);
    } */
}