/* #homeContainer {
    margin-top: 12vh;
} */

/* body {
    background-image: none;
} */

/* Form Title */
.galleryTitle {
    font-family: "Cormorant Garamond";
    /* Matching font family */
    font-weight: 6rem;
    font-size: 400%;
    /* Larger title font size */
    color: white;
    /* Soft, muted pink */
    margin-bottom: 5vh;
    /* Space below the title */
    text-align: center;
    line-height: 110%;
    letter-spacing: 1cap;
    margin-top: 15vh;
    /* Centered title */
}

@media (max-width: 768px) {
    #homeContainer {
            /* background-image: none; */
            padding-top: 20vh;
            background-color: black !important;
        }

                .galleryTitle {
                    font-family: "Cormorant Garamond";
                    /* Matching font family */
                    font-weight: 6rem;
                    font-size: 200%;
                    /* Larger title font size */
                    color: white;
                    /* Soft, muted pink */
                    margin-bottom: 5vh;
                    /* Space below the title */
                    text-align: center;
                    line-height: 110%;
                    letter-spacing: 1cap;
                    margin-top: 5vh;
                    /* Centered title */
                }
}